.evergine-container {
  position: relative;
  display: inline-block;
  background-color: black;
}

.evergine-container .slider {
  position: absolute;
  bottom: 20px;
  left: calc(50% - 100px);
  width: 200px;
}

.evergine-container .buttons-closed-drawer {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 20px;
}
.evergine-container .buttons-opened-drawer {
  position: absolute;
  top: 10px;
  right: 330px;
  width: 20px;
}

.evergine-container .change-view-buttons-closed {
  position: absolute;
  top: 10px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.evergine-container .change-view-buttons-opened {
  position: absolute;
  top: 10px;
  left: 10px;
}

.evergine-container .circularProgress {
  position: absolute;
  top: 50%;
  left: 50%;
}

.evergine-container .loadingContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(211, 211, 211, 0.452);
}

.button-tags {
  margin-top: 20px;
}

.button-tags .MuiPaper-root {
  width: 100%;
}

.dropzone-area .MuiBox-root p {
  color: #9aa4b0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.dropzone-area svg {
  color: #9aa4b0;
} 

.dropzone-area > .MuiBox-root {
  min-height: 158px;
}

.dropzone-area > .MuiBox-root ~ .MuiBox-root {
  min-height: inherit;
}
.background {
  height: 100%; /* You must set a specified height */
  background-position: right; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
}

.container {
  margin: 10px;
}

.left-container {
  margin: 0;
  position: absolute;
  top: 25%;
}

.grecaptcha-badge {
  visibility: hidden;
}

.evergine-login-container {
  height: calc(100vh - 100px);
  min-width: 760px;
}

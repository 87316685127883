.card-icon-right {
    margin-left: auto !important;
}

.case-card .case-card-action-area .MuiCardContent-root {
    padding-bottom: 2px;
}

.case-card .state-chip-container {
    padding-left: 16px;
}

.case-card {
  max-width: 250px;
  margin: auto;
  transition: 0.3s;
  box-shadow: 0 8px 40px -12px rgba(0,0,0,0.3);
  margin-bottom: 30px;
}

.case-card:hover{
  box-shadow: 0 16px 70px -12.125px rgba(0,0,0,0.3);
}

.case-card-action-area{
  min-height: 324px;
}
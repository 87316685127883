.ask-token-container {
  text-align: left;
  background-color: black;
  width: 100%;
  padding-bottom: 16px;
}

/* .ask-token-container .stack {
  padding-left: 20px;
  padding-right: 20px;
} */

.ask-token-container img {
  max-height: 200px;
  width: auto;
}

@media (max-height: 800px) {
  .ask-token-container img {
    max-height: 160px;
  }
}

@media (max-height: 700px) {
  .ask-token-container img {
    max-height: 140px;
  }
}

@media (max-width: 1950px) {
  .ask-token-container img {
    max-height: 160px;
  }
}

@media (max-width: 700px) {
  .ask-token-container img {
    max-height: 140px;
  }
}

.third-step-image {
  position: relative;
}


.ask-token-container p {
  color: white;
  font-size: 14px;
  margin-bottom: 0px;
  max-width: 310px;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}

.ask-token-container h2 {
  color: white;
  margin-bottom: 0px;
}

.ask-token-container h3 {
  color: white;
  margin-bottom: 0px;
  margin-top: 1px;
}

.ask-token-container .container-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ask-token-container .generated-token {
  font-size: 50px;
}

@media (min-width: 600px) {
    .search-drawer-opened {
        padding-left: 519px !important;
    }
}

@media (min-width: 1700px) {
    .search-drawer-opened {
        padding-left: 430px !important;
    }
}

@media (min-width: 1800px) {
    .search-drawer-opened {
        padding-left: 400px !important;
    }
}

@media (min-width: 1800px) {
    .search-drawer-opened {
        padding-left: 370px !important;
    }
}

@media (min-width: 1900px) {
    .search-drawer-opened {
        padding-left: 340px !important;
    }
}

@media (min-width: 2100px) {
    .search-drawer-opened {
        padding-left: 280px !important;
    }
}

@media (min-width: 2200px) {
    .search-drawer-opened {
        padding-left: 200px !important;
    }
}

@media (min-width: 2300px) {
    .search-drawer-opened {
        padding-left: 150px !important;
    }
}

@media (min-width: 2400px) {
    .search-drawer-opened {
        padding-left: 100px !important;
    }
}

@media (min-width: 2500px) {
    .search-drawer-opened {
        padding-left: 50px !important;
    }
}

@media (min-width: 2525px) {
    .search-drawer-opened {
        padding-left: 24px !important;
    }
}